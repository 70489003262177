import { InsertBelowIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import Preview from "schemas/objects/helpers/Preview"

const type = defineType({
  type: "object",
  name: "spacer",
  title: "Spacer",
  icon: InsertBelowIcon,

  fields: [
    defineField({
      name: "size",
      title: "Size",
      type: "number",
      initialValue: 24,
      options: {
        list: [
          {
            title: "Small",
            value: 12,
          },
          {
            title: "Medium",
            value: 24,
          },
          {
            title: "Large",
            value: 36,
          },
          {
            title: "XLarge",
            value: 60,
          },
          {
            title: "XXLarge",
            value: 120,
          },
        ],
      },
    }),
    defineField({
      name: "hr",
      title: "Show line",
      type: "boolean",
      initialValue: false,
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const spacerType = type
export type Spacer = SanityValues["spacer"]
export const spacerSchema = type
