import { EnvelopeIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { ctaSchema } from "schemas/objects/cta"
import { background } from "schemas/objects/helpers/background"
import Preview from "schemas/objects/helpers/Preview"

const type = defineType({
  type: "object",
  name: "fullWidthBanner",
  title: "Full Width Banner",
  icon: EnvelopeIcon,

  fieldsets: [...background.fieldsets],

  fields: [
    ...background.fields,
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "body",
      title: "Body",
      type: "string",
    }),
    defineField({
      name: "cta",
      title: "CTA",
      type: ctaSchema.name,
      initialValue: { type: "link", hideType: true },
    }),
    defineField({
      name: "altStyle",
      title: "Alternative Style",
      type: "boolean",
      initialValue: false,
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const fullWidthBannerType = type
export type FullWidthBanner = SanityValues["fullWidthBanner"]
export const fullWidthBannerSchema = type
