import { StarIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { LOCALES } from "lib/constants"
import { SanityValues } from "sanity.config"
import { imageAlt } from "schemas/chunks/imageAlt"

const type = defineType({
  type: "document",
  name: "review",
  title: "Review",
  icon: StarIcon,

  fields: [
    defineField({
      name: "locale",
      type: "string",
      options: {
        list: LOCALES.map(locale => ({
          title: locale,
          value: locale,
        })) as [{ title: string; value: string }],
      },
    }),
    defineField({
      name: "category",
      title: "Category",
      type: "string",
    }),
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "quote",
      title: "Quote",
      type: "text",
    }),
    defineField({
      name: "authorName",
      title: "Author Name",
      type: "string",
    }),
    defineField({
      name: "authorTitle",
      title: "Author Title",
      type: "string",
    }),
    defineField({
      name: "authorImage",
      title: "Author Image",
      type: "image",
      options: {
        hotspot: true,
      },
      ...imageAlt,
    }),
    defineField({
      name: "logo",
      title: "Logo",
      type: "image",
      ...imageAlt,
    }),
  ],

  preview: {
    select: {
      category: "category",
      quote: "quote",
    },
    prepare: value => ({
      title: `[${value.category}] ${value.quote}`,
    }),
  },
})

export const reviewType = type
export type Review = SanityValues["review"]
export const reviewSchema = type
