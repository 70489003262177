import { InlineElementIcon, UnknownIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

import { background } from "./helpers/background"
import { blockWithEmphasis } from "./helpers/blockWithEmphasis"

const type = defineType({
  type: "object",
  name: "logos",
  title: "Logos",
  icon: UnknownIcon,

  fieldsets: [...background.fieldsets, ...sizing.fieldsets],

  fields: [
    ...background.fields,
    ...sizing.fields,
    defineField({
      name: "whiteBackground",
      title: "White Background",
      type: "boolean",
      initialValue: false,
    }),

    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),

    defineField({
      name: "body",
      title: "Body",
      type: "array",
      of: [blockWithEmphasis],
    }),

    defineField({
      name: "logos",
      title: "Logos",
      type: "array",
      of: [
        defineArrayMember({
          type: "object",
          name: "logosItem",
          title: "Logos Item",
          preview: {
            select: {
              title: "image.alt",
              subtitle: "url",
            },
          },
          icon: InlineElementIcon,

          fields: [
            imageWithAlt,
            defineField({
              name: "height",
              title: "Height",
              type: "number",
              initialValue: 40,
            }),
            defineField({
              name: "url",
              title: "URL",
              type: "string",
            }),
          ],
        }),
      ],
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const logosSchema = type
export type Logos = SanityValues["logos"]
