import { CogIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import {
  ctaAsButtonType2,
  ctaAsLinkType,
  ctaSchema,
  ctaType,
} from "schemas/objects/cta"
import { customUrlType2 } from "schemas/objects/customUrl"
import { textBlockSchema } from "schemas/objects/textBlock"

const headerLink = defineArrayMember({
  type: "object",

  fields: [
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "hasDropdown",
      title: "Has Dropdown",
      type: "boolean",
      initialValue: false,
    }),
    defineField({
      name: "url",
      title: "URL",
      hidden: ({ parent }) => !!parent?.hasDropdown,
      type: customUrlType2.name,
      // optional: true,
    }),
    defineField({
      name: "dropdown",
      title: "Dropdown",
      type: "object",

      hidden: ({ parent }) => !parent?.hasDropdown,

      fields: [
        defineField({
          name: "title",
          title: "Title",
          type: "string",
          hidden: true,
          initialValue: "Dropdown",
        }),
        defineField({
          name: "columns",
          title: "Columns",
          type: "array",
          of: [
            defineArrayMember({
              type: "object",
              name: "linkList",
              title: "Link List",

              fields: [
                defineField({
                  name: "title",
                  title: "Title",
                  type: "string",
                  // optional: true,
                }),
                // defineField({
                //   name: "color",
                //   title: "Color",
                //   type: "string",
                //   options: {
                //     list: [
                //       { title: "primaryLight", value: "#4388CC" },
                //       { title: "primaryMain", value: "#082440" },
                //       { title: "primaryDark", value: "#02162a" },
                //       { title: "secondaryLight", value: "#11BCF0" },
                //       { title: "secondaryMain", value: "#0B80A3" },
                //       { title: "secondaryDark", value: "#064457" },
                //     ],
                //   },
                // }),
                // defineField({
                //   name: "customColor",
                //   title: "Custom Color",
                //   description: "If used overrides the color option",
                //   type: color(),
                //   optional: true,
                // }),
                defineField({
                  name: "type",
                  title: "Type",
                  type: "string",
                  hidden: true,
                  initialValue: "single",
                  options: {
                    list: ["single", "double"],
                  },
                }),
                defineField({
                  name: "items",
                  title: "Items",
                  type: "array",
                  of: [
                    defineArrayMember({
                      type: "object",

                      fields: [
                        defineField({
                          name: "title",
                          title: "Title",
                          type: "string",
                        }),
                        defineField({
                          name: "icon",
                          title: "Icon",
                          type: "image",
                        }),
                        // defineField({
                        //   name: "icon",
                        //   title: "Icon",
                        //   type: icon(),
                        //   // optional: true,
                        // }),
                        defineField({
                          name: "url",
                          title: "URL",
                          type: customUrlType2.name,
                        }),
                      ],
                    }),
                  ],
                }),
              ],
            }),
            defineArrayMember({
              type: "object",
              name: "content",
              title: "Content",

              fields: [
                defineField({
                  name: "title",
                  title: "Title",
                  type: "string",
                }),
                defineField({
                  name: "body1",
                  title: "Body 1",
                  type: "string",
                }),
                defineField({
                  name: "body2",
                  title: "Body 2",
                  type: "customCta",
                }),
                defineField({
                  name: "image",
                  title: "Image",
                  type: "image",
                }),
              ],
            }),
          ],
        }),
        defineField({
          name: "offset",
          title: "Offset",
          description: "Used to position the dropdown",
          type: "number",
        }),
      ],
    }),
  ],
})

const settingsType = defineType({
  type: "document",
  name: "settings",
  title: "Settings",
  icon: CogIcon,

  fieldsets: [
    { name: "topBanner", title: "Top Banner" },
    { name: "header", title: "Header" },
    { name: "footer", title: "Footer" },
    {
      name: "seo",
      title: "SEO",
      description: "These settings can be overriden by individual pages",
    },
  ],

  groups: [
    { name: "topBanner", title: "Top Banner" },
    { name: "header", title: "Header" },
    { name: "footer", title: "Footer" },
    {
      name: "seo",
      title: "SEO",
    },
  ],

  fields: [
    defineField({
      name: "locale",
      type: "string",
      readOnly: true,
      hidden: true,
    }),
    defineField({
      name: "title",
      title: "Title",
      type: "string",
      initialValue: "Web",
      validation: rule => rule.required(),
    }),
    defineField({
      name: "topBannerEnabled",
      title: "Enabled",
      type: "boolean",
      initialValue: false,
      fieldset: "topBanner",
      group: "topBanner",
    }),
    defineField({
      name: "topBannerTitle",
      title: "Title",
      type: "string",
      fieldset: "topBanner",
      group: "topBanner",
    }),
    defineField({
      name: "topBannerSubtitle",
      title: "Subtitle",
      type: "string",
      fieldset: "topBanner",
      group: "topBanner",
    }),
    defineField({
      name: "topBannerCta",
      title: "CTA",
      type: ctaSchema.name,
      initialValue: { type: "link", hideType: true },
      fieldset: "topBanner",
      group: "topBanner",
    }),
    defineField({
      name: "headerTopLink",
      title: "Top link",
      type: "array",
      of: [ctaAsLinkType],
      validation: rules => rules.max(1),
      fieldset: "header",
      group: "header",
    }),
    defineField({
      name: "headerLinks",
      title: "Links",
      type: "array",
      of: [headerLink],
      fieldset: "header",
      group: "header",
    }),
    defineField({
      name: "headerActions",
      title: "Buttons",
      type: "array",
      validation: rules => rules.max(2),
      of: [ctaAsButtonType2],
      fieldset: "header",
      group: "header",
    }),
    defineField({
      name: "footerLogo",
      title: "Logo",
      type: "image",
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerDescription",
      title: "Description",
      type: "text",
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerTopLinks",
      title: "Top Links",
      type: "array",
      // TODO: filter by locale ?
      of: [ctaAsLinkType],
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerColumns",
      title: "Columns",
      type: "array",
      of: [
        defineArrayMember({
          type: "object",

          fields: [
            defineField({
              name: "title",
              title: "Title",
              type: "string",
            }),
            defineField({
              name: "content",
              title: "Columns",
              type: "array",
              of: [
                defineArrayMember({ type: textBlockSchema.name }),
                defineArrayMember({
                  type: ctaSchema.name,
                  initialValue: { type: "link", hideType: true },
                  title: "Link",
                }),
              ],
            }),
          ],
        }),
      ],
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerBottomLinks",
      title: "Bottom Links",
      type: "array",
      // TODO: filter by locale ?
      of: [ctaAsLinkType],
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerBottomText",
      title: "Bottom Text",
      type: "string",
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerCta",
      title: "CTA",
      // TODO
      type: ctaType.name,
      initialValue: { type: "link", hideType: true },
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerFacebook",
      title: "Facebook Link",
      type: "url",
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerTwitter",
      title: "Twitter Link",
      type: "url",
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerLinkedin",
      title: "Linkedin Link",
      type: "url",
      fieldset: "footer",
      group: "footer",
    }),
    defineField({
      name: "footerInstagram",
      title: "Instagram Link",
      type: "url",
      fieldset: "footer",
      group: "footer",
    }),
    // ...seoFields(),
    defineField({
      name: "seoTitle",
      title: "Title",
      type: "string",
      fieldset: "seo",
      group: "seo",
    }),
    defineField({
      name: "seoDescription",
      title: "Description",
      type: "text",
      rows: 3,
      fieldset: "seo",
      group: "seo",
    }),
  ],

  preview: {
    select: {
      locale: "locale",
    },
    prepare: value => ({
      title: `Settings (${value.locale})`,
    }),
  },
})

export { settingsType }
export type Settings = SanityValues["settings"]
export const settingsSchema = settingsType
