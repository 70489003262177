import { DocumentsIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import Preview from "schemas/objects/helpers/Preview"

const type = defineType({
  type: "object",
  name: "reviewIcons",
  title: "Review Icons",
  icon: DocumentsIcon,

  fields: [
    defineField({
      name: "cta",
      title: "CTA",
      type: "customCta",
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const reviewIconsType = type
export type ReviewIcons = SanityValues["reviewIcons"]
export const reviewIconsSchema = type
