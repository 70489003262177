import { defineField } from "@sanity-typed/types"

export const imageAlt = {
  fields: [
    defineField({
      name: "alt",
      type: "string",
    }),
  ],
}

export const imageWithAlt = defineField({
  name: "image",
  title: "Image",
  type: "image",

  fields: [
    defineField({
      name: "alt",
      type: "string",
    }),
  ],
})

export const imageWithAltAndHotspot = defineField({
  name: "image",
  title: "Image",
  type: "image",

  options: {
    hotspot: true,
  },

  fields: [
    defineField({
      name: "alt",
      type: "string",
    }),
  ],
})
