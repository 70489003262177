import { DocumentsIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import Preview from "schemas/objects/helpers/Preview"

const type = defineType({
  type: "object",
  name: "integrationIcons",
  title: "Integration Icons",
  icon: DocumentsIcon,

  fields: [
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "cta",
      title: "CTA",
      type: "customCta",
    }),
    defineField({
      name: "showSalesforce",
      title: "Show Salesforce",
      type: "boolean",
      initialValue: true,
    }),
    defineField({
      name: "showHubspot",
      title: "Show Hubspot",
      type: "boolean",
      initialValue: true,
    }),
    defineField({
      name: "showZoho",
      title: "Show Zoho",
      type: "boolean",
      initialValue: true,
    }),
    defineField({
      name: "showWebhooks",
      title: "Show Webhooks",
      type: "boolean",
      initialValue: true,
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const integrationIconsType = type
export type IntegrationIcons = SanityValues["integrationIcons"]
export const integrationIconsSchema = type
