import { defineArrayMember } from "@sanity-typed/types"

export const blockWithEmphasis = defineArrayMember({
  type: "block",
  styles: [],
  lists: [],
  marks: {
    annotations: [],
    decorators: [{ title: "Emphasis", value: "em" }],
  },
})
