import { changelogSchema } from "./documents/changelog"
import { formSchema } from "./documents/form"
import { pageSchema } from "./documents/page"
import { reviewSchema } from "./documents/review"
import { settingsSchema } from "./documents/settings"
import { bigBannerSchema } from "./objects/bigBanner"
import { contactHeaderSchema } from "./objects/contactHeader"
import { ctaSchema } from "./objects/cta"
import { customUrlSchema } from "./objects/customUrl"
import { fullWidthBannerSchema } from "./objects/fullWidthBanner"
import { homeHeaderSchema } from "./objects/homeHeader"
import { horizontalCardsSchema } from "./objects/horizontalCards"
import { horizontalCards2Schema } from "./objects/horizontalCards2"
import { imageSchema } from "./objects/image"
import { integrationCardsSchema } from "./objects/integrationCards"
import { integrationIconsSchema } from "./objects/integrationIcons"
import { landingHeaderSchema } from "./objects/landingHeader"
import { legalSchema } from "./objects/legal"
import { logosSchema } from "./objects/logos"
import { mediaText1Schema } from "./objects/mediaText1"
import { mediaText2Schema } from "./objects/mediaText2"
import { mediaText3Schema } from "./objects/mediaText3"
import { mediaText4Schema } from "./objects/mediaText4"
import { pricingCardsSchema } from "./objects/pricingCards"
import { pricingShowcaseSchema } from "./objects/pricingShowcase"
import { quoteSchema } from "./objects/quote"
import { reviewIconsSchema } from "./objects/reviewIcons"
import { simpleHeaderSchema } from "./objects/simpleHeader"
import { spacerSchema } from "./objects/spacer"
import { statsInfoSchema } from "./objects/statsInfo"
import { textBlockSchema } from "./objects/textBlock"
import { textSectionSchema } from "./objects/textSection"
import { thankYouSchema } from "./objects/thankYou"
import { verticalCardsSchema } from "./objects/verticalCards"
import { verticalCards2Schema } from "./objects/verticalCards2"
import { verticalMediaText1Schema } from "./objects/verticalMediaText1"
import { verticalMediaText2Schema } from "./objects/verticalMediaText2"
import { youtubeEmbedSchema } from "./objects/youtubeEmbed"

export * from "./documents/changelog"
export * from "./documents/form"
export * from "./documents/page"
export * from "./documents/review"
export * from "./documents/settings"
export * from "./objects/bigBanner"
export * from "./objects/contactHeader"
export * from "./objects/fullWidthBanner"
export * from "./objects/homeHeader"
export * from "./objects/horizontalCards"
export * from "./objects/horizontalCards2"
export * from "./objects/image"
export * from "./objects/integrationCards"
export * from "./objects/integrationIcons"
export * from "./objects/landingHeader"
export * from "./objects/legal"
export * from "./objects/logos"
export * from "./objects/mediaText1"
export * from "./objects/mediaText2"
export * from "./objects/mediaText3"
export * from "./objects/mediaText4"
export * from "./objects/pricingCards"
export * from "./objects/pricingShowcase"
export * from "./objects/quote"
export * from "./objects/reviewIcons"
export * from "./objects/simpleHeader"
export * from "./objects/spacer"
export * from "./objects/statsInfo"
export * from "./objects/textBlock"
export * from "./objects/textSection"
export * from "./objects/thankYou"
export * from "./objects/verticalCards"
export * from "./objects/verticalCards2"
export * from "./objects/verticalMediaText1"
export * from "./objects/verticalMediaText2"
export * from "./objects/youtubeEmbed"

export const documentSchemas = [
  pageSchema,
  settingsSchema,
  formSchema,
  reviewSchema,
  changelogSchema,
]

export const pageComponentSchemas = [
  bigBannerSchema,
  contactHeaderSchema,
  ctaSchema,
  customUrlSchema,
  fullWidthBannerSchema,
  homeHeaderSchema,
  horizontalCards2Schema,
  horizontalCardsSchema,
  imageSchema,
  integrationCardsSchema,
  integrationIconsSchema,
  landingHeaderSchema,
  mediaText1Schema,
  mediaText2Schema,
  mediaText3Schema,
  mediaText4Schema,
  pricingCardsSchema,
  pricingShowcaseSchema,
  quoteSchema,
  reviewIconsSchema,
  simpleHeaderSchema,
  spacerSchema,
  statsInfoSchema,
  textBlockSchema,
  textSectionSchema,
  thankYouSchema,
  legalSchema,
  verticalCards2Schema,
  verticalCardsSchema,
  verticalMediaText1Schema,
  verticalMediaText2Schema,
  youtubeEmbedSchema,
  logosSchema,
]

export const schemas = [...documentSchemas, ...pageComponentSchemas]
