import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { customUrlType } from "schemas/objects/customUrl"

const type = defineType({
  type: "object",
  name: "customCta",
  title: "CTA",

  fields: [
    defineField({
      name: "text",
      title: "Text",
      type: "string",
    }),
    // defineField({
    //   name: "style",
    //   title: "Style",
    //   type: "string",
    //   initialValue: "primary",
    //   hidden: ({ parent }) => parent?.type === "link",
    //   options: {
    //     list: ["primary", "primary-light", "secondary", "tertiary", "link"],
    //   },
    // }),
    defineField({
      name: "url",
      title: "URL",
      type: customUrlType.name,
    }),
    // defineField({
    //   name: "type",
    //   type: "string",
    //   hidden: ({ parent }) => !!parent?.hideType,
    //   options: {
    //     list: ["link", "button"],
    //   },
    // }),
    defineField({
      name: "hideType",
      type: "boolean",
      hidden: true,
    }),
  ],
})

export const ctaType = type
export { type as ctaOptions }
export const ctaAsLinkType = defineArrayMember({
  type: type.name,
  initialValue: { type: "link", hideType: true },
})
export const ctaAsButtonType = defineArrayMember({
  type: type.name,
  initialValue: { type: "button", hideType: true },
})
export const ctaAsButtonType2 = {
  type: type.name,
  initialValue: { type: "button", hideType: true },
}
export type Cta = SanityValues["customCta"]
export const ctaSchema = type
