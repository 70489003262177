import { DocumentVideoIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

import { background } from "./helpers/background"

const type = defineType({
  type: "object",
  name: "youtubeEmbed",
  title: "Youtube Embed",
  icon: DocumentVideoIcon,

  fieldsets: [...background.fieldsets, ...sizing.fieldsets],

  fields: [
    ...background.fields,
    ...sizing.fields,
    defineField({
      name: "url",
      title: "URL",
      type: "string",
    }),
    defineField({
      name: "cta",
      title: "CTA",
      type: "customCta",
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const youtubeEmbedSchema = type
export type YoutubeEmbed = SanityValues["youtubeEmbed"]
