import { UnknownIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import { ctaType } from "schemas/objects/cta"
import { background } from "schemas/objects/helpers/background"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "pricingShowcase",
  title: "Pricing Showcase",
  icon: UnknownIcon,

  fieldsets: [...sizing.fieldsets, ...background.fieldsets],

  fields: [
    ...sizing.fields,
    ...background.fields,
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "price",
      title: "Price",
      type: "string",
    }),
    defineField({
      name: "priceUnit",
      title: "Price Unit",
      type: "string",
    }),
    defineField({
      name: "priceTip",
      title: "Price Tip",
      type: "string",
    }),
    defineField({
      name: "features",
      title: "Features",
      type: "array",
      of: [defineArrayMember({ type: "string" })],
    }),
    defineField({
      name: "cta",
      title: "CTA",
      // TODO
      type: ctaType.name,
      initialValue: { type: "link", hideType: true },
    }),
    imageWithAlt,
    defineField({
      name: "position",
      title: "Media position",
      type: "string",
      initialValue: "left",
      options: {
        list: [
          { title: "Left", value: "left" },
          { title: "Right", value: "right" },
        ],
      },
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const pricingShowcaseSchema = type
export type PricingShowcase = SanityValues["pricingShowcase"]
