import { HomeIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "homeHeader",
  title: "Home Header",
  icon: HomeIcon,

  fieldsets: sizing.fieldsets,

  fields: [
    ...sizing.fields,
    defineField({
      name: "title",
      title: "Title",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "body",
      title: "Body",
      type: "array",
      of: [defineArrayMember({ type: "block" })],
    }),
    defineField({
      name: "showForm",
      title: "Show Form",
      type: "boolean",
      initialValue: false,
    }),
    defineField({
      name: "inputLabel",
      title: "Input Label",
      type: "string",
      hidden: ({ parent }) => !parent?.showForm,
    }),
    defineField({
      name: "ctaLabel",
      title: "CTA Label",
      type: "string",
      hidden: ({ parent }) => !parent?.showForm,
    }),
    defineField({
      name: "formPage",
      title: "Form Page",
      type: "reference",
      to: [{ type: "page" as const }],
      options: {
        filter: ({ document }) => {
          return {
            filter: "locale == $locale",
            params: {
              locale: document["locale"],
            },
          }
        },
      },
    }),
    imageWithAlt,
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const homeHeaderType = type
export type HomeHeader = SanityValues["homeHeader"]
export const homeHeaderSchema = type
