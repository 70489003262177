import uniqBy from "lodash.uniqby"
import { documentSchemas, pageComponentSchemas } from "schemas"
import { ctaType } from "schemas/objects/cta"
import { customUrlType } from "schemas/objects/customUrl"

export const projectionsResolver = (type, toDeep = false) => {
  const schema = [
    ...documentSchemas,
    ...pageComponentSchemas,
    ctaType,
    customUrlType,
  ].find(d => d.name === type)

  if (!schema) return ""

  let query = "...,"

  const getFieldQuery = (field: (typeof schema)["fields"][number]) => {
    switch (field.type) {
      case "slug":
        return `"${field.name}": ${field.name}.current,`

      //   case "pageBuilder":
      //     return `
      //           ${field.name}[] {
      //               ${pageComponentSchemas.map(
      //                 section => `
      //                   _type == "${section.name}" => {
      //                       ${toDeep ? "...," : projectionsResolver(section.name)}
      //                   }
      //                   `
      //               )}
      //           },
      //       `

      case "image":
        return `${field.name} { ..., ${/*asset->*/ ""} },`

      case "object":
        return `
            ${field.name} {
                ${field.fields.map(f => getFieldQuery(f)).join("")}
            },
            `

      case "array":
        if (field.title === "Page Sections") {
          return `${field.name}[] {
            ${pageComponentSchemas.map(
              section => `
                _type == "${section.name}" => {
                    ${toDeep ? "...," : projectionsResolver(section.name)}
                }
                `
            )}
            },`
        }

        if (field.of[0].type === "string") {
          return `${field.name}[],`
        }

        // in case array of references
        if (field.of[0].type === "reference") {
          return `
            ${field.name}[]-> {
                ${field.of[0].to.map(
                  to => `
                    _type=="${to.type}" => {
                        ${toDeep ? "...," : projectionsResolver(to.type, true)}
                    }
                    `
                )}
            },
          `
        }

        return `
            ${field.name}[] {
                ...,
                ${uniqBy(field.of, "type")
                  .map(f => {
                    if (!("type" in f)) return ""

                    // in case array of objects
                    if (f.type === "object") {
                      return `
                        ${
                          field.of[0].name
                            ? field.of.map(
                                // if object has name attribute filter by type
                                of => `_type == "${of.name}" => {
                                    ${of.fields
                                      .map(f => getFieldQuery(f))
                                      .join("")}
                                }`
                              )
                            : // if object has no name reference all its types
                              field.of.map(
                                of =>
                                  `${of.fields
                                    .map(f => getFieldQuery(f))
                                    .join("")}`
                              )
                        }
                        `
                    }

                    // in case array of images
                    if (f.type === "image") {
                      return `
                        ${/*asset->*/ ""}
                        `
                    }

                    if (f.type === "customCta") {
                      return `
                        url {
                            defined(^) => {
                                ...,
                                "internal": internal->slug.current,
                            },
                        }
                        `
                    }

                    if (f.type === "customForm") {
                      return `
                        customForm {
                            defined(^) => customForm-> {
                                ...,
                                "thankYouPage": thankYouPage->slug.current
                            }
                        }
                        `
                    }
                  })
                  .join("")}
            },
        `

      case "reference":
        if (field.name === "customForm") {
          return `
                "customForm": select(
                    defined(customForm) => customForm-> {
                        ...,
                        "thankYouPage": thankYouPage->slug.current
                    }
                ),
            `
        }

        if (toDeep) {
          return `${field.name}->,`
        }

        return `${field.name}-> {
              ${field.to.map(
                to => `
                    _type=="${to.type}" => {
                        ${projectionsResolver(to.type, true)}
                    }
                    `
              )}
        },`

      case "customUrl":
        return `
              ${field.name} {
                  ...,
                  defined(^) => {
                      ...,
                      "internal": internal->slug.current,
                  },
              },
          `

      case "customCta":
        return `
              ${field.name} {
                  ...,
                  url {
                      defined(^) => {
                          ...,
                          "internal": internal->slug.current,
                      },
                  }
              },
          `

      default:
        return `${field.name},`
    }
  }

  if (schema.fields) {
    schema.fields.forEach(field => {
      query += getFieldQuery(field)
    })
  }

  return query
}
