import { PresentationIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import { background } from "schemas/objects/helpers/background"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "mediaText3",
  title: "Media Text 3",
  icon: PresentationIcon,

  fieldsets: [...sizing.fieldsets, ...background.fieldsets],

  fields: [
    ...sizing.fields,
    ...background.fields,
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "subtitle",
      title: "Subtitle",
      type: "string",
    }),
    defineField({
      name: "contentSubtitle",
      title: "Content Subtitle",
      type: "string",
    }),
    defineField({
      name: "contentTitle",
      title: "Content Title",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "body",
      title: "Content Body",
      type: "text",
    }),
    defineField({
      name: "body2",
      title: "Content Body 2 (new)",
      type: "array",
      of: [blockWithEmphasis],
    }),
    imageWithAlt,
    defineField({
      name: "imageHighlight",
      title: "Image Highlight",
      type: "boolean",
      initialValue: false,
    }),
    defineField({
      name: "position",
      title: "Media position",
      type: "string",
      initialValue: "left",
      options: {
        list: [
          { title: "Left", value: "left" },
          { title: "Right", value: "right" },
        ],
      },
    }),
    defineField({
      name: "hideMediaMobile",
      title: "Hide media on mobile",
      type: "boolean",
      initialValue: false,
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const mediaText3Schema = type
export type MediaText3 = SanityValues["mediaText3"]
