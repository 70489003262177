import { groq } from "next-sanity"
import { projectionsResolver } from "schemas/resolver"

// const parseUrl = groq`
// "url": select(
//   !defined(url) => undefined,
//   defined(url.internal)  => url.internal->slug.current,
//   defined(url.external) => url.external,
//   "Error parsing URL!"
// )
// `

const parseUrl = groq`
url {
  defined(^) => {
    ...,
    "internal": internal->slug.current,
  },
}
`

const resolvedUrls = groq`
  content[] {
    ...,
    content[] {
      ...,

      // DynamicColumns
      ${parseUrl},

      // Pricing
      cta {
        ...,
        ${parseUrl}
      },

      body2 {
        ...,
        ${parseUrl}
      },

      // Columns
      content[] {
        ...,
        ${parseUrl}
      },
    },

    "cta": select(
      length(cta) > 0 => cta[] {
        ...,
        ${parseUrl}
      },
      length(cta) == null => cta {
        ...,
        ${parseUrl}
      },
    ),

    // CTABanner
    // cta[] {
    //   ...,
    //   {parseUrl}
    // },

    "customForm": select(
      defined(customForm) => customForm-> {
        ...,
        "thankYouPage": thankYouPage->slug.current
      }
    ),

    // DynamicColumns
    media[] {
      ...,
      "customForm": select(
        defined(customForm) => customForm-> {
          ...,
          "thankYouPage": thankYouPage->slug.current
        }
      )
    },
  }
`

const resolveParents = groq`
  "slug": slug.current,
  parent-> {
    title,
    "slug": slug.current,
    parent-> {
      title,
      "slug": slug.current,
      parent-> {
        title,
        "slug": slug.current,
        parent-> {
          title,
          "slug": slug.current,
        },
      },
    },
  },
  `

const pageFields = groq`
  ...,
  ${resolvedUrls},
  ${resolveParents}
`

export const settingsQuery = groq`
  *[_type == "settings" && locale == $locale][0] {
    ...,
    topBannerCta {
      ...,
      ${parseUrl}
    },
    headerLinks[] {
      ...,
      ${parseUrl},
      dropdown {
        ...,
        columns[] {
          ...,
          items[] {
            ...,
            ${parseUrl},
          }
        },
        bottomLink[] {
          ...,
          ${parseUrl},
        }
      }
    },
    headerActions[] {
      ...,
      ${parseUrl},
    },
    footerColumns[] {
      ...,
      content[] {
        ...,
        ${parseUrl},
      }
    },
    footerCta {
      ...,
      ${parseUrl},
    },
    footerTopLinks[] {
      ...,
      ${parseUrl},
    },
    footerBottomLinks[] {
      ...,
      ${parseUrl},
    },
  }
`

export const pageQuery = groq`
  *[_type == "page" && slug.current == $slug && locale == $locale][0] {
    ${pageFields}

    // Get the translations metadata
    // And resolve the value field in each array item
    // "_translations": *[_type == "translation.metadata" && references(^._id)].translations[].value->{
    //   title,
    //   slug,
    //   language
    // },
  }
`

export const pageSlugsQuery = groq`
  *[_type == "page" && locale == $locale]{
    "slug": slug.current,
    "parent": parent->slug.current,
  }
`

export const pageBySlugQuery = groq`
  *[_type == "page" && slug.current == $slug][0] {
    ${pageFields}
  }
`

export const pageMetaByIdQuery = groq`
  *[_type == "page" && _id == $id][0] {
    parent,
    slug
  }
`

export const pageWithSlugQuery = groq`
  count(
    *[
      _type == "page" &&
      _id != $self &&
      slug.current == $slug &&
      locale == $locale &&
      !(_id in path("drafts.**"))
    ]
  )
`

export const anyBySlugQuery = groq`
  *[slug.current == $slug][0] {
    "slug": slug.current,
  }
`

export const getPageQuery = () => groq`
  *[_type == "page" && slug.current== $slug && locale == $locale][0] {
    ${projectionsResolver("page")}
  }
`

export const getSettingsQuery = () => groq`
  *[_type == "settings" && locale == $locale][0] {
    ${projectionsResolver("settings")}
  }
`

export const getReviewsQuery = () => groq`
  *[_type == "review" && locale == $locale] {
    ${projectionsResolver("settings")}
  }
`
