import { HomeIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { ctaSchema } from "schemas/objects/cta"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "contactHeader",
  title: "Contact Header",
  icon: HomeIcon,

  fieldsets: sizing.fieldsets,

  fields: [
    ...sizing.fields,
    defineField({
      name: "title",
      title: "Title",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "body",
      title: "Body",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "featuresTitle",
      title: "Features Title",
      type: "string",
    }),
    defineField({
      name: "features",
      title: "Features",
      type: "array",
      of: [defineArrayMember({ type: "string" })],
    }),
    defineField({
      name: "cta",
      title: "CTA",
      type: ctaSchema.name,
      initialValue: { type: "link", hideType: true },
    }),
    defineField({
      name: "customForm",
      title: "Custom Form",
      type: "reference",
      to: [{ type: "form" as const }],
      options: {
        filter: ({ document }) => {
          return {
            filter: "locale == $locale",
            params: {
              locale: document["locale"],
            },
          }
        },
      },
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const contactHeaderSchema = type
export type ContactHeader = SanityValues["contactHeader"]
