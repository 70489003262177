import { BlockElementIcon, InlineElementIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import { background } from "schemas/objects/helpers/background"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "horizontalCards2",
  title: "Horizontal Cards 2",
  icon: BlockElementIcon,

  fields: [
    defineField({
      name: "content",
      title: "Columns",
      type: "array",
      of: [
        defineArrayMember({
          type: "object",
          name: "horizontalCards2Item",
          title: "Horizontal Cards Item",
          icon: InlineElementIcon,

          preview: {
            select: {
              title: "title",
              subtitle: "sectionName",
            },
          },

          fieldsets: [...background.fieldsets, ...sizing.fieldsets],

          fields: [
            ...background.fields,
            ...sizing.fields,
            defineField({
              name: "sectionName",
              title: "Section Name",
              description: "Used in the list at the top",
              type: "string",
            }),
            defineField({
              name: "topTag",
              title: "Top Tag",
              type: "string",
            }),
            defineField({
              name: "title",
              title: "Title",
              type: "array",
              of: [blockWithEmphasis],
            }),
            defineField({
              name: "subtitle",
              title: "Subtitle",
              type: "text",
            }),
            defineField({
              name: "content",
              title: "Columns",
              type: "array",
              of: [
                defineArrayMember({
                  type: "object",
                  name: "horizontalCards2ItemItem",
                  title: "Horizontal Cards 2 Item Item",
                  icon: InlineElementIcon,

                  fields: [
                    defineField({
                      name: "title",
                      title: "Title",
                      type: "string",
                    }),
                    defineField({
                      name: "content",
                      title: "Content",
                      type: "array",
                      of: [defineArrayMember({ type: "block" })],
                    }),
                    imageWithAlt,
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const horizontalCards2Schema = type
export type HorizontalCards2 = SanityValues["horizontalCards2"]
export type HorizontalCards2Item =
  SanityValues["horizontalCards2"]["content"][number]
