import { PresentationIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import { background } from "schemas/objects/helpers/background"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "mediaText1",
  title: "Media Text 1",
  icon: PresentationIcon,

  fieldsets: [...sizing.fieldsets, ...background.fieldsets],

  fields: [
    ...sizing.fields,
    ...background.fields,
    defineField({
      name: "subtitle",
      title: "Subtitle",
      type: "string",
    }),
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "contentSubtitle",
      title: "Content Subtitle",
      type: "string",
    }),
    defineField({
      name: "contentTitle",
      title: "Content Title",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "body",
      title: "Content Body",
      type: "text",
    }),
    defineField({
      name: "bottomBody",
      title: "Bottom Body",
      type: "array",
      of: [blockWithEmphasis],
    }),
    imageWithAlt,
    defineField({
      name: "position",
      title: "Media position",
      type: "string",
      initialValue: "left",
      options: {
        list: [
          { title: "Left", value: "left" },
          { title: "Right", value: "right" },
        ],
      },
    }),
    defineField({
      name: "hideMediaMobile",
      title: "Hide media on mobile",
      type: "boolean",
      initialValue: false,
    }),
    defineField({
      name: "style",
      title: "Style",
      type: "string",
      initialValue: "1",
      options: {
        list: [
          { title: "Color 1", value: "1" },
          { title: "Color 2", value: "2" },
          { title: "Color 3", value: "3" },
        ],
      },
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const mediaText1Schema = type
export type MediaText1 = SanityValues["mediaText1"]
