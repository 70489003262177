import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"

const type = defineType({
  type: "object",
  name: "customUrl",
  title: "Custom URL",

  options: {
    collapsed: false,
    collapsible: false,
  },

  fields: [
    defineField({
      name: "external",
      title: "URL",
      type: "url",
      hidden: ({ parent, value }) => !value && !!parent?.internal,
    }),
    defineField({
      name: "internal",
      type: "reference",
      to: [{ type: "page" } as const],
      hidden: ({ parent, value }) => !value && !!parent?.external,
    }),
  ],
})

export const customUrlType = type
export const customUrlType2 = type
export const customUrlWithHiddenFn = (fn: any) =>
  defineType({
    ...type,
    hidden: fn,
  })
export const customUrlWithHiddenType = defineType({
  ...type,
  hidden: ({ parent }) => parent?.type && parent.type !== "link",
})
export type CustomUrl = SanityValues["customUrl"]
export const customUrlSchema = type
