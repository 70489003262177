import { BlockElementIcon, InlineElementIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import { background } from "schemas/objects/helpers/background"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "horizontalCards",
  title: "Horizontal Cards",
  icon: BlockElementIcon,

  fieldsets: [...background.fieldsets, ...sizing.fieldsets],

  fields: [
    ...background.fields,
    ...sizing.fields,
    defineField({
      name: "title",
      title: "Title",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "content",
      title: "Columns",
      type: "array",
      of: [
        defineArrayMember({
          type: "object",
          name: "horizontalCardsItem",
          title: "Horizontal Cards Item",
          icon: InlineElementIcon,

          fields: [
            defineField({
              name: "title",
              title: "Title",
              type: "string",
            }),
            defineField({
              name: "content",
              title: "Content",
              type: "array",
              of: [defineArrayMember({ type: "block" })],
            }),
            imageWithAlt,
          ],
        }),
      ],
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const horizontalCardsType = type
export type HorizontalCards = SanityValues["horizontalCards"]
export type HorizontalCardsItem =
  SanityValues["horizontalCards"]["content"][number]
export const horizontalCardsSchema = type
