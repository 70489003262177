import { TrendUpwardIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import Preview from "schemas/objects/helpers/Preview"

const type = defineType({
  type: "object",
  name: "statsInfo",
  title: "Stats Info",
  icon: TrendUpwardIcon,

  fieldsets: [
    ...[1, 2, 3].flatMap(i => ({
      name: `col${i}`,
      title: `Column ${i}`,
      options: { collapsible: false },
    })),
  ],

  fields: [
    defineField({
      name: `col1Number`,
      title: "Number",
      type: "number",
      fieldset: `col1`,
    }),
    defineField({
      name: `col1Unit`,
      title: "Unit",
      type: "string",
      fieldset: `col1`,
    }),
    defineField({
      name: `col1UnitPosition`,
      title: "Unit Position",
      type: "string",
      initialValue: "right",
      options: {
        list: [
          { value: "left", title: "Left" },
          { value: "right", title: "Right" },
        ],
      },
      fieldset: `col1`,
    }),
    defineField({
      name: `col1Text`,
      title: "Text",
      type: "text",
      fieldset: `col1`,
    }),
    defineField({
      name: `col2Number`,
      title: "Number",
      type: "number",
      fieldset: `col2`,
    }),
    defineField({
      name: `col2Unit`,
      title: "Unit",
      type: "string",
      fieldset: `col2`,
    }),
    defineField({
      name: `col2UnitPosition`,
      title: "Unit Position",
      type: "string",
      initialValue: "right",
      options: {
        list: [
          { value: "left", title: "Left" },
          { value: "right", title: "Right" },
        ],
      },
      fieldset: `col2`,
    }),
    defineField({
      name: `col2Text`,
      title: "Text",
      type: "text",
      fieldset: `col2`,
    }),
    defineField({
      name: `col3Number`,
      title: "Number",
      type: "number",
      fieldset: `col3`,
    }),
    defineField({
      name: `col3Unit`,
      title: "Unit",
      type: "string",
      fieldset: `col3`,
    }),
    defineField({
      name: `col3UnitPosition`,
      title: "Unit Position",
      type: "string",
      initialValue: "right",
      options: {
        list: [
          { value: "left", title: "Left" },
          { value: "right", title: "Right" },
        ],
      },
      fieldset: `col3`,
    }),
    defineField({
      name: `col3Text`,
      title: "Text",
      type: "text",
      fieldset: `col3`,
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const statsInfoType = type
export type StatsInfo = SanityValues["statsInfo"]
export const statsInfoSchema = type
