import { ImageIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAltAndHotspot } from "schemas/chunks/imageAlt"
import Preview from "schemas/objects/helpers/Preview"

const type = defineType({
  type: "object",
  name: "customImage",
  title: "Image",
  icon: ImageIcon,

  fields: [
    imageWithAltAndHotspot,
    defineField({
      name: "align",
      title: "Align",
      type: "string",
      initialValue: "center",
      options: {
        list: ["left", "center", "right"],
      },
    }),
    defineField({
      name: "size",
      title: "Size",
      type: "string",
      initialValue: "md",
      options: {
        list: ["sm", "md", "lg"],
      },
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const imageSchema = type
export type Image = SanityValues["customImage"]
