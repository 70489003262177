import { BlockquoteIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageAlt } from "schemas/chunks/imageAlt"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "quote",
  title: "Quote",
  icon: BlockquoteIcon,

  fieldsets: sizing.fieldsets,

  fields: [
    ...sizing.fields,
    defineField({
      name: "useNewStyle",
      title: "Use New Style",
      type: "boolean",
    }),
    defineField({
      name: "reviews",
      title: "Reviews",
      type: "array",
      of: [
        defineArrayMember({
          type: "reference",
          to: [{ type: "review" as const }],
          options: {
            filter: ({ document }) => {
              return {
                filter: "locale == $locale",
                params: {
                  locale: document["locale"],
                },
              }
            },
          },
        }),
      ],
    }),
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "quote",
      title: "Quote",
      type: "text",
    }),
    defineField({
      name: "authorName",
      title: "Author Name",
      type: "string",
    }),
    defineField({
      name: "authorTitle",
      title: "Author Title",
      type: "string",
    }),
    defineField({
      name: "authorImage",
      title: "Author Image",
      type: "image",
      options: {
        hotspot: true,
      },
      ...imageAlt,
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const quoteType = type
export type Quote = SanityValues["quote"]
export const quoteSchema = type
