import { Slug } from "@sanity/types"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { apiVersion, dataset, projectId } from "lib/sanity.api"
import { pageWithSlugQuery } from "lib/sanity.queries"
import { createClient } from "next-sanity"
import { SanityValues } from "sanity.config"
import { bigBannerSchema } from "schemas/objects/bigBanner"
import { contactHeaderSchema } from "schemas/objects/contactHeader"
import { fullWidthBannerSchema } from "schemas/objects/fullWidthBanner"
import { homeHeaderSchema } from "schemas/objects/homeHeader"
import { horizontalCardsSchema } from "schemas/objects/horizontalCards"
import { horizontalCards2Schema } from "schemas/objects/horizontalCards2"
import { imageSchema } from "schemas/objects/image"
import { integrationCardsSchema } from "schemas/objects/integrationCards"
import { integrationIconsSchema } from "schemas/objects/integrationIcons"
import { landingHeaderSchema } from "schemas/objects/landingHeader"
import { legalSchema } from "schemas/objects/legal"
import { logosSchema } from "schemas/objects/logos"
import { mediaText1Schema } from "schemas/objects/mediaText1"
import { mediaText2Schema } from "schemas/objects/mediaText2"
import { mediaText3Schema } from "schemas/objects/mediaText3"
import { mediaText4Schema } from "schemas/objects/mediaText4"
import { pricingCardsSchema } from "schemas/objects/pricingCards"
import { pricingShowcaseSchema } from "schemas/objects/pricingShowcase"
import { quoteSchema } from "schemas/objects/quote"
import { reviewIconsSchema } from "schemas/objects/reviewIcons"
import { simpleHeaderSchema } from "schemas/objects/simpleHeader"
import { spacerSchema } from "schemas/objects/spacer"
import { statsInfoSchema } from "schemas/objects/statsInfo"
import { textBlockSchema } from "schemas/objects/textBlock"
import { textSectionSchema } from "schemas/objects/textSection"
import { thankYouSchema } from "schemas/objects/thankYou"
import { verticalCardsSchema } from "schemas/objects/verticalCards"
import { verticalCards2Schema } from "schemas/objects/verticalCards2"
import { verticalMediaText1Schema } from "schemas/objects/verticalMediaText1"
import { verticalMediaText2Schema } from "schemas/objects/verticalMediaText2"
import { youtubeEmbedSchema } from "schemas/objects/youtubeEmbed"

// https://maxkarlsson.dev/blog/how-to-make-hierarchical-slugs-in-sanity
async function asyncSlugifier(input) {
  const client = createClient({ projectId, dataset, apiVersion, useCdn: false })
  const parent = await client.fetch("*[_id == $id][0]", {
    id: input.doc.parent?._ref || "",
  })
  // if there's no parent assign an empty string, it will make the function return the current slug as the root
  const parentSlug =
    !parent?.slug?.current || parent?.slug?.current === "index"
      ? ""
      : `${parent?.slug?.current}/`
  const pageSlug = input.doc.title
    .toLowerCase()
    // slugify the title using a simple regex
    .replace(/\s+/g, "-")
    .slice(0, 200)
  return `${parentSlug}${pageSlug}`
}

const type = defineType({
  name: "page",
  title: "Page",
  type: "document",

  fieldsets: [
    {
      name: "settings",
      title: "Page Settings",
      options: {
        collapsed: true,
      },
    },
    {
      name: "seo",
      title: "SEO",
      options: {
        collapsed: true,
      },
    },
  ],
  groups: [
    {
      name: "settings",
      title: "Page Settings",
    },
  ],

  fields: [
    defineField({
      name: "title",
      title: "Title",
      type: "string",
      validation: rule => rule.required(),
    }),
    defineField({
      name: "parent",
      type: "reference",
      to: [{ type: "page" as const }],
      fieldset: "settings",
      options: {
        filter: ({ document }) => {
          return {
            filter: `locale == $locale`,
            params: {
              locale: document.locale,
            },
          }
        },
      },
    }),
    defineField({
      name: "seoTitle",
      type: "string",
      title: "Title",
      fieldset: "seo",
    }),
    defineField({
      name: "seoDescription",
      type: "text",
      title: "Description",
      rows: 3,
      fieldset: "seo",
    }),
    defineField({
      name: "seoHide",
      type: "boolean",
      title: "Hide from search engines",
      initialValue: false,
      fieldset: "seo",
    }),
    defineField({
      name: "locale",
      type: "string",
      readOnly: false,
      hidden: false,
      fieldset: "settings",
    }),
    defineField({
      name: "slug",
      title: "Slug",
      type: "slug",
      options: {
        // source: 'title',
        maxLength: 96,
        source: doc => `/${doc.locale}/${doc.title}`,
        // slugify: (input) =>
        //   input.toLowerCase().replace(/\s+/g, '-').slice(0, 200),
        // source: (doc, options) => ({ doc, options }),
        slugify: asyncSlugifier,
        isUnique: async (value, context) => {
          const pagesWithSlug = await context
            .getClient({ apiVersion })
            .fetch(pageWithSlugQuery, {
              self: context.document._id.replace("drafts.", ""),
              slug: value,
              locale: (context.parent as any).locale,
            })

          return pagesWithSlug === 0
        },
      },
      readOnly: ({ value, currentUser: { roles } }) => {
        return (
          !roles.find(({ name }) => name === "administrator") &&
          (value as Slug)?.current === "index"
        )
      },
      // validation: (rule) => rule.required(),
      fieldset: "settings",
    }),
    defineField({
      name: "whiteBackground",
      title: "White Background",
      type: "boolean",
      initialValue: false,
      fieldset: "settings",
    }),
    defineField({
      name: "hideTopBanner",
      title: "Hide Top Banner",
      type: "boolean",
      initialValue: false,
      fieldset: "settings",
    }),
    defineField({
      name: "simpleHeader",
      title: "Simple Header",
      type: "boolean",
      initialValue: false,
      fieldset: "settings",
    }),
    defineField({
      name: "emptyHeader",
      title: "Empty Header",
      type: "boolean",
      initialValue: false,
      fieldset: "settings",
    }),
    defineField({
      name: "contactHeader",
      title: "Contact Header",
      type: "boolean",
      initialValue: false,
      fieldset: "settings",
    }),
    defineField({
      name: "simpleFooter",
      title: "Simple Footer",
      type: "boolean",
      initialValue: false,
      fieldset: "settings",
    }),
    defineField({
      name: "content",
      type: "array",
      title: "Page Sections",
      of: [
        defineArrayMember({ type: homeHeaderSchema.name }),
        defineArrayMember({ type: landingHeaderSchema.name }),
        defineArrayMember({ type: simpleHeaderSchema.name }),
        defineArrayMember({ type: contactHeaderSchema.name }),

        defineArrayMember({ type: mediaText1Schema.name }),
        defineArrayMember({ type: mediaText2Schema.name }),
        defineArrayMember({ type: mediaText3Schema.name }),
        defineArrayMember({ type: mediaText4Schema.name }),
        defineArrayMember({ type: verticalMediaText1Schema.name }),
        defineArrayMember({ type: verticalMediaText2Schema.name }),

        defineArrayMember({ type: bigBannerSchema.name }),
        defineArrayMember({ type: fullWidthBannerSchema.name }),
        defineArrayMember({ type: statsInfoSchema.name }),

        defineArrayMember({ type: verticalCardsSchema.name }),
        defineArrayMember({ type: verticalCards2Schema.name }),
        defineArrayMember({ type: horizontalCardsSchema.name }),
        defineArrayMember({ type: horizontalCards2Schema.name }),

        defineArrayMember({ type: integrationCardsSchema.name }),
        defineArrayMember({ type: integrationIconsSchema.name }),

        defineArrayMember({ type: quoteSchema.name }),
        defineArrayMember({ type: reviewIconsSchema.name }),

        defineArrayMember({ type: pricingShowcaseSchema.name }),
        defineArrayMember({ type: pricingCardsSchema.name }),

        defineArrayMember({ type: youtubeEmbedSchema.name }),
        defineArrayMember({ type: logosSchema.name }),

        defineArrayMember({ type: legalSchema.name }),
        defineArrayMember({ type: thankYouSchema.name }),

        defineArrayMember({ type: imageSchema.name }),
        defineArrayMember({ type: textBlockSchema.name }),
        defineArrayMember({ type: textSectionSchema.name }),
        defineArrayMember({ type: spacerSchema.name }),
      ],
    }),
  ],

  preview: {
    select: {
      title: "title",
      parent: "parent.title",
    },
    prepare: value => ({
      title: value.title,
      subtitle: value.parent ? `Parent: ${value.parent}` : null,
    }),
  },
})

export const pageSchema = type
export type Page = SanityValues["page"]
