import { defineField, defineType } from "@sanity-typed/types"

// This type is never used directly
const background = defineType({
  type: "object",
  name: "background",

  fieldsets: [
    {
      name: "background",
      title: "Background",
      options: {
        collapsed: true,
      },
    },
  ],

  fields: [
    defineField({
      name: "backgroundColor1",
      title: "Background Color 1",
      type: "string",
      fieldset: "background",
    }),
    defineField({
      name: "backgroundColor2",
      title: "Background Color 2",
      type: "string",
      fieldset: "background",
    }),
    defineField({
      name: "backgroundStyle",
      title: "Background Style",
      type: "string",
      initialValue: "gradient",
      options: {
        list: [
          { title: "Gradient", value: "gradient" },
          { title: "50/50", value: "halfHalf" },
        ],
      },
      hidden: ({ parent }) => !parent?.backgroundColor2,
      fieldset: "background",
    }),
  ],
})

export { background }
