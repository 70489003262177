import { BillIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"

const type = defineType({
  type: "document",
  name: "changelog",
  title: "Changelog",
  icon: BillIcon,

  fields: [
    defineField({
      name: "locale",
      type: "string",
    }),
    defineField({
      name: "content",
      title: "Content",
      type: "array",
      of: [
        defineArrayMember({
          type: "block",
          styles: [
            { title: "Normal", value: "normal" },
            { title: "Small", value: "small" },
            { title: "H1", value: "h1" },
            { title: "H2", value: "h2" },
            { title: "H3", value: "h3" },
            { title: "H4", value: "h4" },
            { title: "H5", value: "h5" },
            { title: "H6", value: "h6" },
            { title: "Quote", value: "blockquote" },
          ],
        }),
        defineArrayMember({
          type: "image",
        }),
      ],
    }),
  ],

  preview: {
    select: {
      locale: "locale",
      name: "name",
    },
    prepare: value => ({
      title: `Changelog (${value.locale})`,
    }),
  },
})

export type Changelog = SanityValues["changelog"]
export const changelogSchema = type
