import { UnknownIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import { ctaSchema } from "schemas/objects/cta"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "verticalMediaText1",
  title: "Vertical Media Text 1",
  icon: UnknownIcon,

  fieldsets: sizing.fieldsets,

  fields: [
    ...sizing.fields,
    defineField({
      name: "subtitle",
      title: "Subtitle",
      type: "string",
    }),
    defineField({
      name: "title",
      title: "Title",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "body",
      title: "Body",
      type: "text",
    }),
    defineField({
      name: "cta",
      title: "CTA",
      type: ctaSchema.name,
      initialValue: { type: "link", hideType: true },
    }),
    imageWithAlt,
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const verticalMediaText1Schema = type
export type VerticalMediaText1 = SanityValues["verticalMediaText1"]
