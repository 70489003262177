import { UnknownIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "textSection",
  title: "Text Section",
  icon: UnknownIcon,

  fieldsets: sizing.fieldsets,

  fields: [
    ...sizing.fields,
    defineField({
      name: "title",
      title: "Title",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "body",
      title: "Body",
      type: "array",
      of: [defineArrayMember({ type: "block" })],
    }),
    defineField({
      name: "showBackgroundImage",
      title: "Show Background Image",
      type: "boolean",
      initialValue: false,
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const textSectionSchema = type
export type TextSection = SanityValues["textSection"]
