import { EnvelopeIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageAlt } from "schemas/chunks/imageAlt"
import { background } from "schemas/objects/helpers/background"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "bigBanner",
  title: "Big Banner",
  icon: EnvelopeIcon,

  fieldsets: [...sizing.fieldsets, ...background.fieldsets],

  fields: [
    ...sizing.fields,
    ...background.fields,
    defineField({
      name: "subtitle",
      title: "Subtitle",
      type: "string",
    }),
    defineField({
      name: "title",
      title: "Title",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "bodyStyle",
      title: "Body Style",
      type: "string",
      options: {
        list: [
          { title: "Text", value: "text" },
          { title: "Quote", value: "quote" },
        ],
      },
    }),
    defineField({
      name: "body",
      title: "Body",
      type: "text",
      hidden: ({ parent }) => parent?.bodyStyle !== "text",
    }),
    defineField({
      name: "quote",
      title: "Quote",
      type: "text",
      hidden: ({ parent }) => parent?.bodyStyle !== "quote",
    }),
    defineField({
      name: "quoteLogo",
      title: "Quote Logo",
      type: "image",
      hidden: ({ parent }) => parent?.bodyStyle !== "quote",
      ...imageAlt,
    }),
    defineField({
      name: "inputLabel",
      title: "Input Label",
      type: "string",
    }),
    defineField({
      name: "ctaLabel",
      title: "CTA Label",
      type: "string",
    }),
    defineField({
      name: "formPage",
      title: "Form Page",
      type: "reference",
      to: [{ type: "page" as const }],
      options: {
        filter: ({ document }) => {
          return {
            filter: "locale == $locale",
            params: {
              locale: document["locale"],
            },
          }
        },
      },
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const bigBannerType = type
export type BigBanner = SanityValues["bigBanner"]
export const bigBannerSchema = type
