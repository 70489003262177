import { InlineElementIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import { background } from "schemas/objects/helpers/background"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "verticalCards2",
  title: "Vertical Cards 2",
  icon: InlineElementIcon,

  fieldsets: [...background.fieldsets, ...sizing.fieldsets],

  fields: [
    ...background.fields,
    ...sizing.fields,
    // defineField({
    //   name: "title",
    //   title: "Title",
    //   type: "string",
    // }),
    defineField({
      name: "content",
      title: "Columns",
      type: "array",
      of: [
        defineArrayMember({
          type: "object",
          name: "verticalCards2Item",
          title: "Item",
          icon: InlineElementIcon,

          fields: [
            imageWithAlt,
            defineField({
              name: "title",
              title: "Title",
              type: "string",
            }),
            defineField({
              name: "content",
              title: "Content",
              type: "array",
              of: [defineArrayMember({ type: "block" })],
            }),
          ],
        }),
      ],
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const verticalCards2Type = type
export type VerticalCards2 = SanityValues["verticalCards2"]
export type VerticalCards2Item =
  SanityValues["verticalCards2"]["content"][number]
export const verticalCards2Schema = type
