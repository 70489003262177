import { defineField, defineType } from "@sanity-typed/types"

// This type is never used directly
const sizing = defineType({
  type: "object",
  name: "sizing",

  fieldsets: [
    {
      name: "sizing",
      title: "Sizing",
      options: {
        collapsed: true,
      },
    },
  ],

  fields: [
    defineField({
      name: "sizingTop",
      title: "Space Top",
      type: "string",
      fieldset: "sizing",
    }),
    defineField({
      name: "sizingBottom",
      title: "Space Bottom",
      type: "string",
      fieldset: "sizing",
    }),

    defineField({
      name: "sizingTopMobile",
      title: "Space Top Mobile",
      type: "string",
      fieldset: "sizing",
    }),
    defineField({
      name: "sizingBottomMobile",
      title: "Space Bottom Mobile",
      type: "string",
      fieldset: "sizing",
    }),
  ],
})

export { sizing }
