import { RocketIcon } from "@sanity/icons"
import { defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { background } from "schemas/objects/helpers/background"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "integrationCards",
  title: "Integration Cards",
  icon: RocketIcon,

  fieldsets: [...sizing.fieldsets, ...background.fieldsets],

  fields: [
    ...sizing.fields,
    ...background.fields,
    defineField({
      name: "titleLeft",
      title: "Title Left",
      type: "string",
    }),
    defineField({
      name: "bodyLeft",
      title: "Body Left",
      type: "text",
    }),
    defineField({
      name: "titleRight",
      title: "Title Right",
      type: "string",
    }),
    defineField({
      name: "bodyRight",
      title: "Body Right",
      type: "text",
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const integrationCardsType = type
export type IntegrationCards = SanityValues["integrationCards"]
export const integrationCardsSchema = type
