import { InlineElementIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { imageWithAlt } from "schemas/chunks/imageAlt"
import { customUrlType2 } from "schemas/objects/customUrl"
import { background } from "schemas/objects/helpers/background"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "verticalCards",
  title: "Vertical Cards 1",
  icon: InlineElementIcon,

  fieldsets: [...sizing.fieldsets, ...background.fieldsets],

  fields: [
    ...sizing.fields,
    ...background.fields,
    defineField({
      name: "title",
      title: "Title",
      type: "string",
    }),
    defineField({
      name: "content",
      title: "Columns",
      type: "array",
      of: [
        defineArrayMember({
          type: "object",
          name: "verticalCardsItem",
          title: "Vertical Cards 1 Item",
          icon: InlineElementIcon,

          fields: [
            defineField({
              name: "title",
              title: "Title",
              type: "string",
            }),
            defineField({
              name: "content",
              title: "Content",
              type: "array",
              of: [defineArrayMember({ type: "block" })],
            }),
            defineField({
              name: "link",
              title: "Link",
              type: customUrlType2.name,
            }),
            defineField({
              name: "cta",
              title: "CTA",
              type: "customCta",
            }),
            imageWithAlt,
          ],
        }),
      ],
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const verticalCardsType = type
export type VerticalCards = SanityValues["verticalCards"]
export type VerticalCardsItem = SanityValues["verticalCards"]["content"][number]
export const verticalCardsSchema = type
