import { HomeIcon } from "@sanity/icons"
import { defineArrayMember, defineField, defineType } from "@sanity-typed/types"
import { SanityValues } from "sanity.config"
import { background } from "schemas/objects/helpers/background"
import { blockWithEmphasis } from "schemas/objects/helpers/blockWithEmphasis"
import Preview from "schemas/objects/helpers/Preview"
import { sizing } from "schemas/objects/helpers/sizing"

const type = defineType({
  type: "object",
  name: "simpleHeader",
  title: "Simple Header",
  icon: HomeIcon,

  fieldsets: [...sizing.fieldsets, ...background.fieldsets],

  fields: [
    ...sizing.fields,
    ...background.fields,
    defineField({
      name: "title",
      title: "Title",
      type: "array",
      of: [blockWithEmphasis],
    }),
    defineField({
      name: "body",
      title: "Body",
      type: "array",
      of: [defineArrayMember({ type: "block" })],
    }),
    defineField({
      name: "showContainer",
      title: "Show Container",
      type: "boolean",
      initialValue: true,
    }),
  ],

  components: {
    preview: () => <Preview title={type.title} icon={type.icon} />,
  },
})

export const simpleHeaderSchema = type
export type SimpleHeader = SanityValues["simpleHeader"]
